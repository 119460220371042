import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Article from '../components/Article'

const BlogPost = ({ data }) => {
  const { slug: blogSlug } = data.contentfulBlog
  const {
    excerpt: { excerpt },
    seoImage,
    slug,
    date,
    title,
    titleIcon,
    content,
  } = data.contentfulBlogPost

  return (
    <>
      <Seo
        title={title}
        description={excerpt}
        pathname={`${blogSlug}/${slug}`}
        image={seoImage?.fixed.src}
      />

      <Article
        landingPageSlug={blogSlug}
        label={date}
        title={title}
        titleIcon={titleIcon}
        content={content}
        latestPosts={data.allContentfulBlogPost.nodes}
      />
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPost($slug: String!) {
    contentfulBlog {
      slug
    }
    allContentfulBlogPost(
      limit: 3
      filter: { slug: { ne: $slug } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date(formatString: "MMMM DD, YYYY")
        title
        titleIcon
        excerpt {
          excerpt
        }
        slug
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      excerpt {
        excerpt
      }
      seoImage {
        fixed(height: 630, width: 1200) {
          src
        }
      }
      slug
      date(formatString: "MMMM DD, YYYY")
      title
      titleIcon
      content {
        raw
        references {
          ...ContentfulRichTextReferences
        }
      }
    }
  }
`
